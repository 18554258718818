.order-submit-screen {
  position: relative;
  z-index: 0;
  /* margin-top: 30px; */
}

.order-submit-screen h2.ant-typography {
  /* font-family: var(--font-sfpro); */
  font-size: 26px;
  font-weight: 600;
  color: var(--color-dark);
  line-height: 36px;
}

.order-submit-screen .start-order-list .ant-list-footer {
  padding: 20px 13px;
}

.order-submit-screen .ant-list-item:last-child {
  border-block-end: 1px solid rgba(5, 5, 5, 0.06);
}

.order-submit-screen .pdf-list-data .ant-list-footer {
  text-align: center;
}

.order-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
}

.header-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.header-img .ant-image-img {
  height: 220px;
  object-fit: cover;
}

.header-img .ant-image {
  width: 100%;
}

.order-header-content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.order-header-left .ant-image-img {
  width: 200px;
}

.order-header-left {
  padding-right: 150px;
  text-align: center;
}

.order-header-left h1 {
  color: white;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 26px;
}

.order-header-right .ant-image-img {
  height: 200px;
  object-fit: contain;
}

.order-submit-screen h2 {
  text-align: center;
  margin-top: 30px;
  font-size: 36px;
}

.order-submit-screen h5 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0;
}

.order-list {
  margin-top: 30px;
  margin-bottom: 40px;
}

.order-list li {
  font-size: 16px;
  margin-top: 10px;
}

.order-list li span {
  font-weight: 600;
}

.canalytics-app-meta {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.canalytics-app-left {
  flex: 0 0 auto;
  max-width: 50%;
  text-align: center;
}

.canalytics-app-left .ant-image-img {
  width: 100%;
  max-width: 250px;
  height: auto;
  object-fit: cover;
}

.canalytics-app-right {
  flex: 0 0 auto;
  max-width: 50%;
  padding-left: 70px;
}

.canalytics-app-right h2.ant-typography {
  text-align: left;
  color: #18285c;
  font-size: 26px;
}

.canalytics-app-content .ant-typography {
  margin-bottom: 20px;
  display: inline-block;
  color: #18285c;
  font-size: 16px;
}

.canalytics-app-content strong {
  font-weight: 700;
}

.canalytics-app-content a.ant-typography {
  margin-bottom: 0;
  font-weight: 500;
  color: var(--color-blue);
}

.canalytics-analysis-app-left {
  padding-right: 70px;
  padding-left: 0;
}

.canatytics-web-app {
  margin-top: 50px;
  text-align: center;
}

.canatytics-web-app .ant-image-img {
  width: 100%;
  height: auto;
}
.loaderSpin{
  display: block;
  margin-left: auto;
  margin-right: auto;
  top:50%;
  transform: translateY(-50%);

}

.canatytics-web-app .ant-typography {
  display: inline-block;
  color: #18285c;
  font-size: 17px;
  margin-top: 20px;
  font-weight: 600;
  text-align: center;
  padding: 0 20px;
}

/* MEDIA QUERIES */
@media (min-width: 1500px) {
  .order-header-left .ant-image-img {
    width: 250px;
  }

  .order-header-left h1 {
    font-size: 36px;
  }

  .order-submit-screen h2.ant-typography {
    font-size: 36px;
  }
}

@media (max-width: 1200px) {
  .canalytics-app-left {
    flex: 0 0 auto;
    max-width: 40%;
    text-align: center;
  }

  .canalytics-app-right {
    flex: 0 0 auto;
    max-width: 60%;
  }

}

@media (max-width: 992px) {
  .order-submit-screen .ant-col {
    margin-bottom: 20px;
  }

  .order-header,
  .header-img .ant-image-img {
    height: 190px;
  }

  .order-header-right .ant-image-img {
    height: 170px;
  }
}

@media (max-width: 576px) {
  .order-submit-screen .start-order-list .ant-list-footer {
    padding: 20px 20px;
  }

  .order-header-left {
    padding-right: 60px;
  }

  .order-header-left .ant-image-img {
    width: 160px;
  }

  .order-header,
  .header-img .ant-image-img {
    height: 150px;
  }

  .order-header-right .ant-image-img {
    height: 130px;
  }

  .order-submit-screen h2.ant-typography {
    font-size: 22px;
    line-height: 28px;
  }

  .order-submit-screen h5 {
    font-size: 14px;
    font-weight: 400;
  }

  .order-list li {
    font-size: 14px;
  }

  .canalytics-app-meta {
    flex-direction: column;
  }

  .canalytics-app-right {
    padding: 0;
    flex: 0 0 auto;
    max-width: 100%;
  }

  .canalytics-app-left {
    padding: 0;
    flex: 0 0 auto;
    max-width: 100%;
  }
}


.pdf-list-data .ant-list-item-meta-content{

  display: flex  !important;
  justify-content: space-between;
}